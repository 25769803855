import Repository from "./Repository";

const GET_INFO = "/seo_home";
const GET_ABOUT = "/about";
// const GET_PROD_DESIGN = "/product_design";
// const GET_PROD_CATEGORIES = "/productcategories";
const GET_PROJECT = "/project_seo";
const GET_OUR_PRESENCE = "/our_presence_seo";
const GET_KEY_MANAGE = "/key_managerial_personnel";
const GET_OUR_MANUFACTURING = "/our_manufacturing";
const GET_CAREERS_SEO = "/careers_seo";
const GET_NEWS_EVENTS = "/news_&_events";
const GET_DESIGN_SUPPORT = "/design_support";

export default {
  getMetaInfo(payload) {
    return Repository.post(transformRoute(GET_INFO), payload);
  },
  getMetaAbout(payload) {
    return Repository.post(transformRoute(GET_ABOUT), payload);
  },
  // getMetaProductDesign(payload) {
  //   return Repository.post(transformRoute(GET_PROD_DESIGN), payload);
  // },
  // getMetaProductCategories(payload) {
  //   return Repository.post(transformRoute(GET_PROD_CATEGORIES), payload);
  // },
  getMetaProject(payload) {
    return Repository.post(transformRoute(GET_PROJECT), payload);
  },
  getMetaOurPresence(payload) {
    return Repository.post(transformRoute2(GET_OUR_PRESENCE), payload);
  },
  getMetaKeyManagerial(payload) {
    return Repository.post(transformRoute2(GET_KEY_MANAGE), payload);
  },
  getMetaOurManufacturing(payload) {
    return Repository.post(transformRoute2(GET_OUR_MANUFACTURING), payload);
  },
  getMetaCareers(payload) {
    return Repository.post(transformRoute2(GET_CAREERS_SEO), payload);
  },
  getMetaNewsEvents(payload) {
    return Repository.post(transformRoute2(GET_NEWS_EVENTS), payload);
  },
  getMetaDesignSupport(payload) {
    return Repository.post(transformRoute2(GET_DESIGN_SUPPORT), payload);
  },
};

const transformRoute = (route) => {
  return `/forms/get${route}`;
};
const transformRoute2 = (route) => {
  return `/forms/formGet${route}`;
};
